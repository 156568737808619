<template>
  <div>
    <commonPageBanner :url="backUrl" />
    <div class="detailBox">
      <h2 class="title">{{ detail.title }}</h2>
      <div class="date">
        发布时间： {{ detail.created_time | secondFormat("LL") }}
      </div>
      <div class="descBox content_area" v-html="detail.content"></div>
    </div>
    <div
      class="applyBox"
      v-if="detail.has_apply_link && !status && detail.content_type == 1"
    >
      <div class="applyTitle">在线申请活动</div>
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="80px"
          class="form"
          :show-message="false"
          label-position="left"
      >
          <el-row>
            <el-col :span="11">
              <el-form-item label="企业名称" required prop="company_name">
                <el-input
                    v-model="form.company_name"
                    placeholder="请输入企业名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
              <el-form-item label="联系人" required prop="contacts">
                <el-input v-model="form.contacts" placeholder="请输入联系人"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="职位" required prop="job_title">
              <el-input
                  v-model="form.job_title"
                  placeholder="请输入职位名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="联系方式" required prop="contact_information">
              <el-input v-model="form.contact_information" placeholder="请输入联系方式"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                  type="textarea"
                  placeholder="备注信息"
                  v-model="form.remark"
                  maxlength="200"
                  :rows="6"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="applyBtns">
        <el-button @click="restForm" class="applyBtn applyBtnCan" size="medium">取 消</el-button>
        <el-button :loading="btnLoading" class="confirmBtn applyBtn" type="primary" size="medium" @click="apply('form')">确 定</el-button>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="conlseVisible" width="300px">
      <div>
        <div class="openTitle">
          暂无公司信息, 请到控制台-企业中心 完善您的公司信息
        </div>
        <div class="tc open">
          完善公司信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>
<script>
import commonPageBanner from "~tcb/components/layouts/pageBanner";
export default {
  components: { commonPageBanner },
  data() {
    return {
      desc: "<p>I believe I can fly</p>",
      img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/apply.png",
      conlseVisible: false,
      form: {
        company_name: "",
        contacts: "",
        contact_information: "",
        job_title: "",
        remark: ""
      },
      rules: {},
      detail: {},
      backUrl:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/Banner_tree.png",
      status: 0,
      userInfo: "",
      btnLoading: false
    };
  },
  mounted() {
    this.getDetail();
    this.getStatus();
    this.userInfo = this.USER_INFO;
  },
  computed: {
  },
  methods: {
    restForm() {
      this.$refs.form.resetFields()
    },
    apply(formName) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      } else {
        if (!this.USER_INFO.company_id) {
          this.conlseVisible = true;
          return;
        } else if (!this.USER_INFO.aila_no) {
          this.$message({
            message: "普通公司不能进行活动报名",
            type: "warning",
          });
          return;
        } else {
          this.submit(formName)
        }
      }
    },
    toConsole() {
      this.$router.push({path:"/console/company/basic"})
      // this.$router.push({
      //   path: "/memberCenter",
      //   query: {
      //     url: "company/basic",
      //   },
      // });
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.btnLoading= true
          let data = await this.$store.dispatch("API_index/applyEnroll", {
            user_id: this.USER_INFO.id,
            source: this.PJSource,
            event_id: this.$route.query.id,
            contacts: this.form.contacts,
            contact_information: this.form.contact_information,
            job_title: this.form.job_title,
            company: this.form.company_name,
            remark: this.form.remark,
          });
          this.btnLoading= false
          if (data.success) {
            this.status = 1;
            this.$message({
              message: "提交成功",
              type: "success",
            });
          }
        } else {
          return false;
        }
      });
    },
    async getStatus() {
      let data = await this.$store.dispatch("API_index/nowStatus", {
        user_id: this.USER_INFO.id,
        source: this.PJSource,
        event_id: this.$route.query.id,
      });
      if (data.success) {
        if (JSON.stringify(data.data) != "{}") {
          this.status = 1;
        }
      }
    },
    async getDetail() {
      let data = await this.$store.dispatch("API_index/getStaticContent", {
        id: this.$route.query.id,
      });
      if (data.success) {
        this.detail = data.data;
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__footer {
  padding-top: 0;
}
.detailBox {
  padding-bottom: 20px;
}
.title {
  font-size: 21px;
  font-weight: 900;
  line-height: 50px;
  text-align: center;
}
.date {
  text-align: center;
  color: #909399;
  padding-bottom: 20px;
}
.applyBox {
  margin: 69px auto 60px;
  width: 868px;
  //height: 412px;
  background: #FFFFFF;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  .applyTitle {
    padding:10px 0 10px 20px;
    color: #1F292E;
    font-size: 14px;
    border-bottom: 1px solid #F2F2F3;
  }
  .form {
    padding: 25px 20px 20px;
  }
  .applyBtns {
    text-align: center;
    padding: 0 0 32px;
    .applyBtn {
      width: 115px;
    }
    .applyBtnCan {
      background: #FFFFFF;
    }
    .applyBtnCan:hover{
      color: #606266!important;
      border-color: #DCDFE6!important;
    }
    .confirmBtn {
      background: #3DB166;
      border: none;
    }
  }
}
.demo-input-suffix {
  padding-left: 20px;
  display: flex;
  white-space: nowrap;

  align-items: center;
  .input {
    width: 220px;
  }
}

.openTitle {
  font-weight: bold;
}
.open {
  margin: 20px 0 0;
  i {
    color: #4a5a81;
    margin-left: 5px;
    border: 1px solid #4a5a81;
    border-radius: 50%;
  }
}
</style>
